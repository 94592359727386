import configureStore from './configureStore'

// const preloadedState = {
//   userInfo: window.userInfo
// }

// const store = configureStore(preloadedState)

const store = configureStore()

export default store

