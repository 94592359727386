// 页面路由
export const routeData = [
  {
    path: '/',
    componentH5: () => import('../views/MC/index'),
    componentPc: () => import('../views/PC/index')
  },
  {
    path: '/see/:id',
    componentH5: () => import('../views/PC/see'),
    componentPc: () => import('../views/PC/see')
  }
];
